<template>
  <section class="ensure-list">
    <van-search
      v-model="keyword"
      placeholder="请输入搜索关键词"
      @search="onSearch"
      @cancel="onCancel"
    />
    <Sticky>
      <DropdownMenu>
        <DropdownItem v-model="status" :options="statusList" />
        <DropdownItem v-if="!isAuthAfter" v-model="process_type" :options="processTypeList" />
        <DropdownItem ref="timeDropDown" :show-toolbar="false">
          <DatetimePicker
            v-model="currentDate"
            type="date"
            :max-date="maxDate"
            :min-date="minData"
            @confirm="onTimeConfirm"
            @cancel="onTimeCancel"
          />
          <template v-slot:title>
            <span>提单日期</span>
            <span v-if="query.time" class="xa-txt-12 xa-txt-regular"
              >({{ query.time }})</span
            >
          </template>
        </DropdownItem>
      </DropdownMenu>
    </Sticky>
    <list
      v-model="isLoadingMore"
      :finished="isLoadingFinished"
      :finished-text="listFinishedText"
      :immediate-check="false"
      @load="onLoadMore"
    >
      <div
        v-for="(item, index) in list"
        :key="index + item.guid"
        class="xa-cell list-cell"
        @click="onClick(item)"
      >
        <EnsureImages class="cell-img" v-bind="item" width="65" height="65" />
        <div class="xa-flex">
          <div class="cell-title xa-cell">
            <div class="xa-flex">{{ item.service_no }}</div>
            <div
              class="xa-txt-regular xa-txt-14 cell-status"
              :class="statusColor[item.status_text]"
            >
              {{ item.status_text }}
            </div>
          </div>
          <p class="cell-subtitle">{{ item.show_name }}</p>
          <p class="cell-tip xa-txt-12">
            <span class="xa-txt-regular">{{ item.client_user }}</span>
            <span class="xa-txt-secondary">{{ item.apply_at }}</span>
          </p>
        </div>
      </div>
    </list>
  </section>
</template>
<script>
// 故障服务单-列表/ 支持全保/授权售后单
// 此页面是缓存页面
import basePage, { LOAD_ACTION } from '@/mixins/basePage'
import {
  DropdownMenu,
  DropdownItem,
  DatetimePicker,
  List,
  Search,
  Sticky
} from 'vant'
import { getEnsureServiceList } from '@/apis/list'
import request from '@/controllers/request'
import EnsureImages from '@/components/EnsureImages'
export default {
  name: 'Cache-EsListView',
  mixins: [basePage],
  config: {
    navigationBarTitleText: '故障服务单',
    fetchDataFn: 'fetchData'
  },
  components: {
    VanSearch: Search,
    DatetimePicker,
    DropdownMenu,
    DropdownItem,
    EnsureImages,
    List,
    Sticky
  },
  data() {
    return {
      keyword: '',
      status: '',
      statusColor: {
        已完成: 'xa-txt-green',
        待支付: 'xa-txt-yellow',
        处理中: 'xa-txt-yellow',
        待接单: 'xa-txt-yellow'
      },
      statusList: [
        {
          text: '全部状态',
          value: ''
        },
        {
          text: '待接单',
          value: 'WAIT_RECEIVE'
        },
        {
          text: '处理中',
          value: 'HANDLE_ING'
        },
        {
          text: '待支付',
          value: 'WAIT_PAY'
        },
        {
          text: '已完成',
          value: 'END'
        }
      ],
      process_type: '',
      processTypeList: [
        {
          text: '全部单据',
          value: ''
        },
        {
          text: '农业无人机',
          value: 'P_SERIAL'
        },
        {
          text: '充电站/发电机',
          value: 'GENERATOR'
        },
        {
          text: '关怀服务',
          value: 'CARE_PLAN'
        }
      ],
      minData: new Date(2019, 1, 1),
      currentDate: new Date(),
      maxDate: new Date(),
      selectData: '',
      query: {
        psize: 20,
        pid: 1,
        time: '',
        keyword: ''
      },
      list: [],
      isLoadingMore: false,
      isLoadingFinished: false
    }
  },
  watch: {
    process_type() {
      this.query.pid = 1
      this.$_fetchDataWidthUi(this.fetchData())
    },
    status() {
      this.query.pid = 1
      this.$_fetchDataWidthUi(this.fetchData())
    }
  },
  computed: {
    listFinishedText() {
      return `已加载全部数据-共${this.list.length}条`
    },
    // 判断是不是授权售后单据
    isAuthAfter() {
      return this.$route.query.is_auth_after_sale
    }
  },
  methods: {
    onSearch(keyword) {
      this.query.keyword = keyword
      this.$_fetchDataWidthUi(this.fetchData(LOAD_ACTION.REFRESH))
    },
    onCancel() {
      if (this.query.keyword) {
        this.query.keyword = ''
        this.$_fetchDataWidthUi(this.fetchData(LOAD_ACTION.REFRESH))
      }
    },
    onClick(item) {
      this.$router.push(item.href || `/Ensure-Detail?guid=${item.guid}`)
    },
    onTimeConfirm(time) {
      if (this.selectData != time) {
        this.selectData = time
        this.query.pid = 1
        this.query.time = this.selectData.toLocaleDateString()
        this.$_fetchDataWidthUi(this.fetchData())
      }
      this.$refs.timeDropDown.toggle()
    },
    onTimeCancel() {
      if (this.query.time) {
        this.query.pid = 1
        this.query.time = this.selectData = ''
        this.$_fetchDataWidthUi(this.fetchData())
      }
      this.$refs.timeDropDown.toggle()
    },
    async fetchData(loadAction) {
      if (loadAction === LOAD_ACTION.REFRESH) {
        this.query.pid = 1
      }
      const { url, data } = this.$_extractRequestInfoFormRoute()
      const requestData = {
        ...data,
        ...this.query,
        ...this.$route.query,
        process_type: this.process_type,
        status: this.status
      }
      const list = await request(
        url
          ? {
              url,
              data: requestData
            }
          : getEnsureServiceList(requestData)
      )
      list.forEach(item => {
        item.images = (item.images || []).slice(0, 1)
      })
      if (this.query.pid === 1) {
        this.list = list
      } else {
        this.list.push(...list)
      }
      this.isLoadingFinished = list.length < this.query.psize
    },
    async onLoadMore() {
      if (this.isLoadingFinished) {
        this.isLoadingMore = false
        return
      }
      this.isLoadingMore = true
      this.query.pid++
      await this.fetchData()
      this.isLoadingMore = false
    }
  }
}
</script>
<style lang="scss">
.ensure-list {
  .list-cell {
    margin-top: 8px;
    padding: 10px;
    background-color: #ffffff;
  }
  .cell-img {
    margin-right: 5px;
  }
  .cell-title {
    font-size: 17px;
    line-height: 26px;
  }
  .cell-subtitle {
    font-size: 14px;
    line-height: 25px;
  }
  .cell-tip {
    display: flex;
    justify-content: space-between;
    color: #aaa;
  }
}
</style>
